import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {ISaleOrder} from 'app/blocks/model/sale-order.model';

export interface ICreateSaleOrder extends IEntityAction {
    entity?: ISaleOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export interface IUpdateSaleOrder extends IEntityAction {
    entity?: ISaleOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export class CreateSaleOrder implements ICreateSaleOrder {
    static readonly type = '[Sale Order] Create Sale Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: ISaleOrder,
        public uploadDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}

export class UpdateSaleOrder implements IUpdateSaleOrder {
    static readonly type = '[Sale Order] Update Sale Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: ISaleOrder,
        public uploadDocuments?: IMposfile[],
        public deletedDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}
